<template>
  <div>
    <b-row>
      <b-col cols="12" md="12">
        <div class="flex float-right">

          <!-- <b-button
              v-b-tooltip.hover.top="'Print122'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-icon mr-1 text-primary shadow-lg"
              border-colour="#1B9AAA"
              variant="lighten-2"
              size="sm"
              @click="printData()"

          >
            <feather-icon size="20" icon="PrinterIcon"/>
          </b-button> -->

          <b-button
              v-b-tooltip.hover.top="'Duplicate week'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :disabled="eventSettings.dataSource.length ===0"
              class="btn-icon mr-1 text-primary shadow-lg"
              variant="lighten-2"
              size="sm"
              @click="duplicateShifts()"
          >
            <feather-icon size="20" icon="CopyIcon"/>
          </b-button>
          <b-button
              v-if="draftLength ===0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              disabled
              variant="dark"
              @click="publishScheduler()"
          >
            <span> All Shifts Published</span>

          </b-button>

          <b-button
              v-if="draftLength !==0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="draftLength !==0?'dark':'light-dark'"
              @click="publishScheduler()"
          >
            <span v-if="draftLength ===1">Publish {{ draftLength }} Shift</span>
            <span v-if="draftLength >1">Publish {{ draftLength }} Shift</span>
          </b-button>

        </div>
      </b-col>
    </b-row>

    <b-card bg-variant="" class="mt-2 full-height" >
      <b-row>
        <b-col cols="12" md="2">
          <v-select
              v-model="filters['job_role_id']"
              :options="JObRoleFilterOption"
              :reduce="JObRoleFilterOption => JObRoleFilterOption.id"
              label="text"
              placeholder="Select Profession"
              value="id"
          />
        </b-col>

        <b-col cols="12" md="2">
          <v-select
              v-model="selected_role_ids"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(roleOptions) => roleOptions.id"
              class="mb-1"
              label="name"
              placeholder="Select Role"
          />
        </b-col>

        <b-col cols="12" md="2">
          <v-select
              v-model="selectedShiftType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[
                                      {title:'All', value:'all'},
                                      {title:'Vacant', value:'vacant'},
                                      {title:'Filled', value:'filled'},
                                      {title:'Draft', value:'draft'},
                                      {title:'Cancelled', value:'cancelled'},
                                      ]"
              class="mb-1"
              label="title"
              placeholder="Select Shift Type"
          />
        </b-col>

        <b-col cols="12" md="2">
          <v-select
              v-model="selectedUser"
              :options="save_employee.filter(m => m.id !== -1)"
              :reduce="save_employee => save_employee.id"
              label="text"
              placeholder="Select User"
              value="id"
              multiple
          />
        </b-col>
        <b-col cols="12" md="3">
          <v-select
              v-model="selected_service_ids"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="serviceOption"
              :reduce="serviceOption => serviceOption.id"
              class="mb-2"
              label="name"
              placeholder="Select Service"
              multiple
          />
        </b-col>




        <b-col md="2">


          <!-- <div class="col">

          </div> -->

          <!--                <b-button-->
          <!--                 v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
          <!--                 class="btn-icon mr-1 btn btn-block"-->
          <!--                 variant="primary"-->
          <!--                 @click="createShift()"-->
          <!--             >+ Vacant Shift-->
          <!--             </b-button>-->
        </b-col>
      </b-row>

      <b-overlay
          :show="show"
          rounded="sm"
      >

        <!--        :resizeStart="dragStop"-->
        <!--        :resizeStop="dragStop"-->
        <div class="schedule-vue-sample">
          <div class="control-section">
            <ejs-schedule
                id="scheduler"
                ref="scheduler"
                :cellDoubleClick="false"
                :current-view="currentView"
                :dataBinding="dataBinding"
                :dateHeaderTemplate="dateHeaderTemplate"
                :eventClick="onEventClick"
                :eventDoubleClick="onEventClick"
                :eventRendered="onEventRendered"
                :eventSettings="eventSettings"
                :group="group"
                :popupOpen="openpopup"
                :quickInfoTemplates="quickInfoTemplates"
                :dragStart="dragStart"
                :dragStop="dragStop"
                :workDays="workDays"
                :resource-header-template="resourceHeaderTemplate"
                :rowAutoHeight="true"
                :show-header-bar="true"
                :timeScale="timeScale"
                :allowTextWrap='true'
                :textWrapSettings='wrapSettings'
                cssClass="quick-info-template"
            >


              <e-views>

                <e-view :eventTemplate="weekTemplate" cssClass='e-outline' displayName="TODAY(S) SHIFTS" option="Agenda"/>
                <e-view :eventTemplate="weekTemplate"  displayName="DAY" option="TimelineDay"/>
                <e-view :eventTemplate="weekTemplate" displayName="WEEK" option="TimelineWeek">
                </e-view>
                <e-view :eventTemplate="monthTemplate" displayName="MONTH" option="TimelineMonth"/>

              </e-views>
              <e-resources>
                <e-resource

                    :allow-multiple="allowMultiple"
                    :data-source="projectResourceDataSource"
                    :allowTextWrap='true'
                    color-field="color"
                    field="group_id"
                    id-field="id"
                    name="Projects"
                    text-field="text"
                    title="Choose Project"
                    id="res"
                    expandedField="IsExpand"

                />
                <e-resource

                    :allow-multiple="allowMultiple"
                    :data-source="employeeDataSource"
                    color-field="color"
                    field="user_id"
                    group-i-d-field="groupId"
                    id-field="id"
                    image-field="image"
                    name="Employees"
                    text-field="text"
                    title="Employee"
                />
              </e-resources>
            </ejs-schedule>
          </div>
        </div>

        <!--            <ejs-contextmenu id="contextmenu" ref="menuObj" cssClass="schedule-context-menu" target=".e-schedule" :items="menuItems" :beforeOpen="onContextMenuBeforeOpen" :select="onMenuItemSelect" ></ejs-contextmenu>-->

      </b-overlay>

    </b-card>


    <side-bar ref="AddShiftSideBar"
              :cell-data="selectedCell"
              :is-add-new-shift-sidebar-active.sync="isAddNewShiftSidebarActive"
              :leave-data="leaveAndUnAvailabilities"
              @createShift="createShiftcompleted()"
    />
    <user-create-shift-side-bar ref="AddUserCreateShiftSideBar"
                                :cell-data="selectedCell"
                                :is-add-new-user-create-shift-sidebar-active.sync="isAddNewUserCreateShiftSidebarActive"
                                :leave-data="leaveAndUnAvailabilities"
                                @createShiftUser="createShiftCompletedInUser()"
    />
    <edit-side-bar ref="EditShiftSideBar" :cell-data="selectedCellEdit"
                   :is-edit-shift-sidebar-active.sync="isEditShiftSidebarActive"
                   @editShift="editShift()"/>
    <duplicate-side-bar ref="DuplicateShiftSideBar"
                        :cell-data="selectedCell"
                        :is-duplicate-shift-sidebar-active.sync="isDuplicateShiftSidebarActive"/>
    <duplicate-single-shift-side-bar ref="DuplicateSingleShiftSideBar"
                                     :cell-data="selectedCellView"
                                     :groups="projectResourceDataSource"
                                     :is-duplicate-single-shift-sidebar-active.sync="isDuplicateSingleShiftSidebarActive"
                                     :users="employeeDataSource"
                                     @editShift="editShift()"/>
    <!--    <view-application-side-bar ref="ViewApplicationSideBar"-->
    <!--                               :cell-data="selectedCellView"-->
    <!--                               :is-view-application-sidebar-active.sync="isViewApplicationSidebarActive"/>-->
  </div>
</template>

<script>
import {
  Agenda,
  DragAndDrop,
  Print,
  Resize,
  SchedulePlugin,
  TimelineMonth,
  TimelineViews,
  Week,
  Day
} from '@syncfusion/ej2-vue-schedule'
import {extend} from '@syncfusion/ej2-base'
import Vue from 'vue'
import header from './header.vue'
import quickInfoTContentmplate from './CellView/content'
import content from './CellView/content'
import quickInfoHeaderTemplate from './CellView/header'
import quickInfoFooterTemplate from './CellView/footer'
import MomentMixin from '@/mixins/MomentMixin'
import moment from 'moment'
import weekTemplate from '@/views/scheduler/eventsTemplates/weekTemplate'
import monthTemplate from '@/views/scheduler/eventsTemplates/monthTemplate'
import dateHeaderTemplate from '@/views/scheduler/DateHeaderTemplates/dateHeaderTemplate'
import duplicateSingleShiftSideBar from '@/views/scheduler/CellEdit/duplicateSingleShiftSideBar'

import {
  BAlert,
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BRow,
  VBModal,
  VBToggle,
  VBTooltip,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SideBar from './sideBar'
import UserCreateShiftSideBar from './userCreateShift'
import EditSideBar from '@/views/scheduler/CellEdit/editSideBar'
import DuplicateSideBar from '@/views/scheduler/CellEdit/duplicateSideBar'
import {mapActions, mapGetters} from 'vuex'
import shift from '@/apis/modules/shift'
import SettingsAPI from "@/apis/modules/settings";
import Filter from '@/mixins/FilterMixin'
import settings from "@/apis/modules/settings";



export default Vue.extend({
  name: 'Index',
  mixins: [MomentMixin, Filter],

  components: {
    UserCreateShiftSideBar,
    BFormCheckbox,
    weekTemplate,
    BOverlay,
    duplicateSingleShiftSideBar,
    DuplicateSideBar,
    EditSideBar,
    content,
    SideBar,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,

    BModal,
    BAlert,
    BBadge,
    BButtonGroup

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip
  },

  comments: {
    SchedulePlugin,
    extend

  },
  data () {
    return {
      save_shifts:[],
      selectedServiceType:{
        title:'Select service type',
        value:''
      },
      selectedShiftType:'',
      wrapSettings: { wrapMode: 'Content' },
      // gridSettings: {
      //     rowHeight: 60
      // },
      showNonClinical: false,
      JObRoleFilterOption: [],
      filters: {},

      leaveDataMap: [],
      unAvailabilitiesDataMap:[],
      leaveAndUnAvailabilities:[],
      show: false,
      query: {
        value: '',
        start: null,
        end: null
      },
      sheduleData: [],
      isDuplicateSingleShiftSidebarActive: false,
      isAddNewShiftSidebarActive: false,
      isAddNewUserCreateShiftSidebarActive:false,
      isEditShiftSidebarActive: false,
      isDuplicateShiftSidebarActive: false,
      popOpen: true,
      eventTemplate: false,
      filter: {
        selectedRole: {}
      },
      modelOpen: false,
      sidebarOpen: false,
      selectedCell: {},
      selectedCellEdit: {},
      selectedCellView: {},
      timeScale: {
        enable: false,
        interval: 60,
        slotCount: 2

      },
      dayOfWeekValue: '1',
      workDays: [1, 2, 3, 4, 5],
      resourceHeaderTemplate () {
        return {template: header}
      },
      weekTemplate () {
        return {template: weekTemplate}

      },
      monthTemplate () {
        return {template: monthTemplate}

      },
      dateHeaderTemplate () {
        return {template: dateHeaderTemplate}
      },


      quickInfoTemplates: {
        header () {
          return {template: quickInfoHeaderTemplate}
        },
        content () {
          return {template: quickInfoTContentmplate}
        },
        footer () {
          return {template: quickInfoFooterTemplate}
        }

      },
      timezoneData: [
        {
          timezone: 'America/New_York',
          text: '(UTC-05:00) Eastern Time'
        },
        {
          timezone: 'UTC',
          text: 'UTC'
        },
        {
          timezone: 'Europe/Moscow',
          text: '(UTC+03:00) Moscow+00 - Moscow'
        },
        {
          timezone: 'Asia/Kolkata',
          text: '(UTC+05:30) India Standard Time'
        },
        {
          timezone: 'Australia/Perth',
          text: '(UTC+08:00) Western Time - Pert'
        }
      ],
      projectResourceDataSource: [],
      employeeDataSource: [],
      eventSettings: {
        dataSource: '',
        cssClass: 'schedule-cell-dimension'
      },
      selectedDate: new Date(2018, 3, 4),
      currentView: 'TimelineWeek',
      allowMultiple: true,
      IsExpand:'',
      group: {
        resources: ['Projects', 'Employees'],
        allowGroupEdit: false
      },
      formDragEdit: {
        service_id: ''
      },
      draftLength: '',
      serviceOption: [],
      roleOptions:[],
      selected_service_ids: [],
      selected_role_ids:[],
      save_employee:[],
      selectedUser:'',
    }
  },

  provide: {
    schedule: [Agenda, TimelineViews, TimelineMonth, Week,  Day, Resize, DragAndDrop, Print]
  },
  computed: {
    ...mapGetters(['getPracticeId'])
  },
  watch: {
    'query.value' (values) {
      this.getShiftData()

    },
    selectedServiceType(){
      this.getShiftData()
    },
    selectedShiftType(value) {
      this.getselectedShiftData(value)
    },
    'filter.selectedRole' (val) {
    },
    showNonClinical () {
      this.getShiftData()
    },
    'selectedUser'(val){
      this.UserFilter(val)
    },


    'selected_service_ids'(val){
      this.serviceFilter(val)
    },
    'selected_role_ids'(val){
      this.roleFilter(val)
    },
  },

  methods: {
    ...mapActions(['setPracticeId']),

    filterQueryUpdate () {
      this.getUserData(true)
      this.getShiftData()
    },
    weekButtonFunction(){
      this.$refs.scheduler.changeCurrentView('TimelineWeek',0)
    },
    todayButtonFunction(){
      this.$refs.scheduler.changeCurrentView('Agenda',0)
    },
    dayButtonFunction(){
      this.$refs.scheduler.changeCurrentView('TimelineDay',0)
    },
    monthButtonFunction(){
      this.$refs.scheduler.changeCurrentView('TimelineMonth',0)

    },
    openpopup (agrs) {
      if (!agrs.data.Guid) {
        agrs.cancel = true
        this.selectedCell = agrs
        if (agrs.data.group_id === -1){
          this.createShift()
        }else {
          this.createShiftClickCell(true)
        }

      }
    },
    dataBinding (val) {
      const start_date = moment(val.query.params.find(x => x.key === 'StartDate').value).format('YYYY-MM-DD')
      const end_date = moment(val.query.params.find(x => x.key === 'EndDate').value).format('YYYY-MM-DD')
      this.query.start = start_date
      this.query.end = end_date
      this.query.value = `${start_date}_${end_date}`

    },
    async publishScheduler () {
      this.$swal({
        title: 'Are you sure you want to publish rota?',
        text: 'Staff whose shifts have changed will be notified.',
        showCancelButton: true,
        imageUrl: require('@/assets/images/icons/publish.png'),
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: 'Yes, publish',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {


        if (result.value) {
          this.showSuccessMessage('Shift was Published!')
          //  this.$swal({
          //     icon: 'success',
          //     title: 'Published!',
          //     customClass: {
          //       confirmButton: 'btn btn-success',
          //     },
          //   })

          await this.publishData()
        }
      })
    },
    async publishData () {
      try {
        let arrayList = []
        this.eventSettings.dataSource.forEach((m) => {
          if (m.state === 'draft') {
            arrayList.push(m.Id)
          }
        })
        await shift.publishData({'shift_ids': arrayList})
        await this.getShiftData()

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    onEventRendered: function (args) {
      let categoryColor = args.data.color
      if (!args.element || !categoryColor) {
        return
      }
      args.element.style.backgroundColor = categoryColor
    },
    printData () {
      let scheduleObj = document.getElementById('scheduler').ej2_instances[0]
      scheduleObj.print()
    },
    editShift () {
      this.isEditShiftSidebarActive = false
      this.isDuplicateSingleShiftSidebarActive = false

      this.getShiftData()
    },
    createShiftClickCell(){
      this.$refs.AddUserCreateShiftSideBar.cellClicked()
      this.isAddNewUserCreateShiftSidebarActive = !this.isAddNewUserCreateShiftSidebarActive
    },
    createShift (is_cell_clicked = false) {
      if (is_cell_clicked) this.$refs.AddShiftSideBar.cellClicked()
      this.isAddNewShiftSidebarActive = !this.isAddNewShiftSidebarActive
    },
    createShiftCompletedInUser () {
      this.isAddNewUserCreateShiftSidebarActive = !this.isAddNewUserCreateShiftSidebarActive
      this.getShiftData()

    },
    createShiftcompleted () {
      this.isAddNewShiftSidebarActive = !this.isAddNewShiftSidebarActive
      this.getShiftData()

    },

    onTimezoneDropDownChange (args) {
      this.$refs.ScheduleObj.ej2Instances.timezone = args.value
    },
    async onPopUpOpen (agrs) {
      //agrs.Cancel = true;
    },
    // onCellDoubleClick: function (args) {
    // args.cancel = true;
    // },
    async onEventClick (agrs) {

      if ((agrs.event.state === 'leave' || agrs.event.state === 'un-availability' )) {
        agrs.cancel = true
      } else {
        this.selectedCell = await agrs
      }


    },
    async onCellClick (agrs) {

    },
    dragStart () {

    },
    async dragStop (val) {
      try {
        if (val.data.group_id === -1){
          this.formDragEdit.is_assignee_locum = val.data.is_assignee_locum
          this.formDragEdit.salary_staff_rate = val.data.salary_staff_rate
          this.formDragEdit.salary_staff_rate_type = val.data.salary_staff_rate_type
          this.formDragEdit.locum_rate = val.data.locum_rate
          this.formDragEdit.is_visible_locum = val.data.is_visible_locum
          this.formDragEdit.service_id = val.data.service.id
          this.formDragEdit.assignee_id = val.data.user_id !== -1? val.data.user_id : null
          this.formDragEdit.job_role_id =''
          if (val.data.vacant_job_roles === null){
            this.formDragEdit.job_role_ids = [val.data.job_role.id]
          }else {
            this.formDragEdit.job_role_ids =  val.data.vacant_job_roles.map(x => x.job_role.id)
          }
          this.formDragEdit.shift_type_id = val.data.shift_type.id
          this.formDragEdit.start = moment(this.momentFormat(val.data.StartTime, 'YYYY-MM-DD') + ` ` + this.momentFormat(val.data.StartTime, 'HH:mm:ss')).utc().format('YYYY-MM-DD HH:mm:ss')
          this.formDragEdit.end = moment(this.momentFormat(val.data.EndTime, 'YYYY-MM-DD') + ` ` + this.momentFormat(val.data.EndTime, 'HH:mm:ss')).utc().format('YYYY-MM-DD HH:mm:ss')
          let updateResponse = await shift.updateShift(val.data.Id, this.formDragEdit)
          await this.getShiftData()
          if(updateResponse.data.error) {
            this.showErrorMessage('There is an existing shift in this time slot')
          }else {
            this.showSuccessMessage('Shift Updated Successfully')
          }

        }else {
          if (val.data.user_id === undefined){
            this.showErrorMessage("can't move this group field")
            await this.getShiftData()
            return
          }
          this.formDragEdit.is_assignee_locum = val.data.is_assignee_locum
          this.formDragEdit.salary_staff_rate = val.data.salary_staff_rate
          this.formDragEdit.salary_staff_rate_type = val.data.salary_staff_rate_type
          this.formDragEdit.locum_rate = val.data.locum_rate
          this.formDragEdit.is_visible_locum = val.data.is_visible_locum
          this.formDragEdit.service_id = val.data.service.id
          this.formDragEdit.assignee_id = val.data.user_id ? val.data.user_id : null
          this.formDragEdit.job_role_id = val.data.group_id
          this.formDragEdit.job_role_ids = []
          this.formDragEdit.shift_type_id = val.data.shift_type.id
          this.formDragEdit.start = moment(this.momentFormat(val.data.StartTime, 'YYYY-MM-DD') + ` ` + this.momentFormat(val.data.StartTime, 'HH:mm:ss')).utc().format('YYYY-MM-DD HH:mm:ss')
          this.formDragEdit.end = moment(this.momentFormat(val.data.EndTime, 'YYYY-MM-DD') + ` ` + this.momentFormat(val.data.EndTime, 'HH:mm:ss')).utc().format('YYYY-MM-DD HH:mm:ss')
          let updateResponse = await shift.updateShift(val.data.Id, this.formDragEdit)
          await this.getShiftData()

          if(updateResponse.data.error) {
            this.showErrorMessage('There is an existing shift in this time slot')
          }else {
            this.showSuccessMessage('Shift Updated Successfully')
          }


        }




        // this.loading = false
        // this.$emit('editShift')
      } catch (error) {
        this.convertAndNotifyError(error)
        // this.loading = false
      }
    },
    async getUserData (filtered) {
      try {
        this.show = true

        const response = await shift.userListForScheduler(this.filterQuery)
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data.filter(user => user.roles.length !== 0)


        this.projectResourceDataSource = users.reduce(function (filtered, option) {
          option.job_roles.forEach((m) => {
            var obj = {
              text: m.name,
              id: m.id,
              color: '#00C49A',
              isGroup: true,
              IsExpand:true
            }
            filtered.push(obj)
          })

          filtered = [...new Map(filtered.map(item => [item.id, item])).values()]

          return filtered
        }, [])
        if (!filtered) {
          this.JObRoleFilterOption = this.projectResourceDataSource
        }

        this.projectResourceDataSource.splice(0, 0,{
          text: 'Vacant Shifts',
          id: -1,
          // color: '#00C49A',
          isGroup: true,
          IsExpand:true
        })

        const locumUserResponse  = await shift.locumList(this.filterQuery)
        const locumStaffUsers = locumUserResponse.data.data.map((x) => ({
          text: `${x.assignee.first_name}  ${x.assignee.last_name}`,
          id: x.assignee.id,
          groupId: x.assignee.locum.job_role.id,
          image: '1',
          is_have_profile:x.assignee.photo_url?true:false,
          is_locum:true
        }))

        const salariedStaffUsers= users.map((x) => ({
          text: `${x.first_name} ${x.last_name}`,
          id: x.id,
          groupId: x.job_roles[0].id,
          image: '1',
          is_have_profile:x.photo_url?true:false,
          is_locum:false
        }))

        salariedStaffUsers.push({
          text: 'Vacant Shifts',
          id: -1,
          groupId: -1,
          image: '1',
          is_have_profile:false,
          is_locum:false
        })

        this.employeeDataSource = salariedStaffUsers.concat(locumStaffUsers)
        this.save_employee =  salariedStaffUsers
        // this.save_employee =  salariedStaffUsers.concat(locumStaffUsers)
        this.show = false

      } catch (error) {

        this.convertAndNotifyError(error)
        this.show = false
      }

    },

    async getUserLeave () {
      try {
        const payload = {
          start: this.query.start,
          end: this.query.end,
          user_ids: this.employeeDataSource.map((x) => x.id)
        }
        if (!payload.start && !payload.end) return

        const response = await shift.getUserLeaves(payload)
        this.leaveDataMap = response.data.data.map((x) => ({
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          group_id: x.user.job_roles[0].id,
          user_id: x.user.id,
          user: null,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          job_role: x.user.job_roles,
          Subject: 'Leave',
          state: 'leave',
          is_day: x.is_day,
          service: []

        }))

      } catch (error) {

      }
    },
    async getUsersUnAvailabilities () {
      try {
        const payload = {
          start: this.query.start,
          end: this.query.end,
          user_ids: this.employeeDataSource.map((x) => x.id)
        }
        if (!payload.start && !payload.end) return

        const response = await shift.getUsersUnAvailabilities(payload)
        this.unAvailabilitiesDataMap = response.data.data.map((x) => ({
          EndTime: new Date(x.day_unavailability_ends),
          StartTime: new Date(x.day_unavailability_start),
          starTimeForEdit: new Date(x.day_unavailability_start),
          endTimeForEdit: new Date(x.day_unavailability_ends),
          group_id: x.user.job_roles[0].id,
          user_id: x.user.id,
          user: null,
          job_role: x.user.job_roles,
          Subject: 'UnAvailabilities',
          state: 'un-availability',
          is_day: 0,
          service: []
        }))
      } catch (error) {
      }
    },
    async getJobRoleList() {
      try {
        const Response = await SettingsAPI.getRolesList();
        this.roleList = Response.data.data.map((x) => ({
          label: x.name,
          value: x.id,
          professions: x.professions,
        }))
        if(array[0]){
          return array
        }else {
          return null
        }
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    filterLabel(profession){
      try{
        let array = [];
        array = profession.map((x)=>{
          return (this.roleList.find(e=>e.value === x))
        })

        return array
      }catch(error){
      }
    },
    async getShiftData () {
      if (!this.query.start || !this.query.end) return
      try {
        this.show = true
        let calenderData = await shift.getCalenderData(this.query.start, this.query.end,this.selectedServiceType.value)
        let withAssignee = calenderData.data.data.filter(function (obj) {
          return obj.assignee !== null
        })
        let notAssignee = calenderData.data.data.filter(function (obj) {
          return obj.assignee === null
        })
        await this.getJobRoleList()
        let sheduleDataMap1 = withAssignee.map((x) => ({
          Id: x.id,
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          user_id: x.assignee.id,
          user: x.assignee,
          group_id: x.job_role.id,
          Subject: x.service === null ? '.' : x.service.name,
          service: x.service === null ? [] : x.service,
          service_id: x.service === null ? [] : x.service.id,
          shift_type: x.shift_type === null ? [] : x.shift_type,
          state: x.state,
          job_role: x.job_role,
          role_id: x.role_id ? this.filterLabel(x.role_id) : null,
          break_minutes: x.break_minutes,
          applications_count: x.applications_count,
          receivedSwapRequests: x.received_swap_requests ? x.received_swap_requests : null,
          note: x.note,
          rate: x.rate,
          IsAllDay: false,
          is_covid_shift: x.is_covid_shift,
          is_assignee_locum: x.is_assignee_locum,
          is_visible_locums: x.is_visible_locums,
          salary_staff_rate: x.salary_staff_rate,
          salary_staff_rate_type: x.salary_staff_rate_type,
          locum_rate: x.locum_rate,
          practice_name:x.practice.name,
          is_pcn:x.p_c_n_id? true:false,
          pcn:x.pcn,
          programme:x.pcn_programme,
          location:x.pcn_location,
          vacant_job_roles:null

        }))
        let sheduleDataMap2 = notAssignee.map((x) => ({
          Id: x.id,
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          group_id: -1,
          user_id: -1,
          user: null,
          vacant_job_roles: x.vacant_job_roles,
          job_role: null,
          Subject: x.service === null ? '.' : x.service.name,
          service: x.service === null ? [] : x.service,
          service_id: x.service === null ? [] : x.service.id,
          shift_type: x.shift_type === null ? [] : x.shift_type,
          role_id: x.role_id ? this.filterLabel(x.role_id) : null,
          state: x.state,
          break_minutes: x.break_minutes,
          applications_count: x.applications_count,
          // sent_swap_requests: x.sent_swap_requests ? x.sent_swap_requests : null,
          note: x.note,
          rate: x.rate,
          IsAllDay: false,
          is_covid_shift: x.is_covid_shift,
          is_assignee_locum: x.is_assignee_locum,
          is_visible_locums: x.is_visible_locums,
          salary_staff_rate: x.salary_staff_rate,
          salary_staff_rate_type: x.salary_staff_rate_type,
          locum_rate: x.locum_rate,
          practice_name:x.practice.name,
          is_pcn:x.p_c_n_id? true:false,
          pcn:x.pcn,
          programme:x.pcn_programme,
          location:x.pcn_location,
        }))
        await this.getUserLeave()
        await this.getUsersUnAvailabilities ()

        let sheduleDataMap = sheduleDataMap1.concat(sheduleDataMap2).concat(this.leaveDataMap).concat(this.unAvailabilitiesDataMap)
        this.leaveAndUnAvailabilities = this.leaveDataMap.concat(this.unAvailabilitiesDataMap)

        let mappedStates = sheduleDataMap.map((level, i) => {
          switch (level.state) {
          case 'draft':
            if (level.user_id !== -1) {
              level.color = ' #F7F7F7'
              level.border = "#B2B2B2"
            } else {
              level.color = ' #ECEFFD'
              level.border = "#4361EE"
            }
            // level.color ='#605d5d'
            // level.color = '#ECEFFD'
            //level.border = "black"
            //level.width = '100px'
            break
          case 'cancel':
            // level.color ='#605d5d'
            level.color = '#E5E5E5'
            level.border = "black"
            //level.width = '100px'
            break
          case 'un-availability':
            // level.color ='#605d5d'
            level.color = '#ffffff'
            break
          case 'leave':
            // level.color ='#605d5d'
            level.color = '#ffffff'
            break
          case 'published':
            if (level.user_id !== -1) {
              level.color = '#00C49A'
            } else {
              level.color = '#4361EE'
            }
            if (level.applications_count !== 0) {
              level.color = '#EFA12C'
            }
            break
          }
          // // ...
          return level
        })

        const sheduleDataFinalArray = mappedStates.filter((m) =>m.is_pcn !== true || m.state !== 'draft')
        this.eventSettings = {dataSource: sheduleDataFinalArray}
        this.draftLength = sheduleDataFinalArray.filter(m => m.state === 'draft').length
        this.save_shifts = await sheduleDataFinalArray


        // set the service filter data
        await this.getServices(sheduleDataFinalArray)




        // this.$set(this.$refs.scheduler.eventSettings, 'dataSource', sheduleDataMap)
        this.show = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.show = false
      }
    },
    async filterChangesAndRemoveUser(data){
      if (data.length === 0){
        this.showSuccessMessage('no vacant shifts')
        const salariedStaffUsers = []
        salariedStaffUsers.push({
          text: 'Vacant Shifts',
          id: -1,
          groupId: -1,
          image: '1',
          is_have_profile:false,
          is_locum:false
        })
        this.employeeDataSource = salariedStaffUsers
      }else {

        const salariedStaffUsers = data.map((x) => ({
          text: x.user === null ? 'Vacant Shifts' : (`${x.user.first_name} ${x.user.last_name}`),
          id: x.user === null ? -1 : x.user.id,
          groupId: x.user === null ? -1 : x.job_role.id,
          image: '1',
          is_have_profile: x.user === null ? false : (x.photo_url ? true : false),
          is_locum: false
        }))
        this.employeeDataSource = await _.uniqBy(salariedStaffUsers, 'id')
      }
    },
    async getselectedShiftData(value){
      if (value === null){
        await this.getUserData()
        await this.getShiftData()
      }
      switch (value.value) {
      case 'draft':
        this.eventSettings = {dataSource: this.save_shifts.filter (x => x.state === 'draft')}
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource)
        break
      case 'vacant':
        this.eventSettings = {dataSource: this.save_shifts.filter (x => x.state === 'published' && x.user === null)}
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource)

        break
      case 'filled':
        this.eventSettings = {dataSource: this.save_shifts.filter (x => x.state === 'published' && x.user !== null)}
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource)

        break
      case 'cancelled':
        this.eventSettings = {dataSource: this.save_shifts.filter (x => x.state === 'cancelled' || (x.state !== 'published' && x.user === null))}
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource)
        break
      case 'all':
        this.eventSettings = {dataSource: this.save_shifts}
        await this.getUserData()
        await this.getShiftData()
        break
      }

    },
    async duplicateShifts () {
      try {
        this.isDuplicateShiftSidebarActive = !this.isDuplicateShiftSidebarActive
      } catch (error) {
        this.convertAndNotifyError(error)
        this.show = false
      }
    },

    async getServices(data) {
      try {
        const serviceArray = await data.filter((m)=> m.service !== []).map((x)=>({
          name:x.service.name,
          id:x.service.id
        }))
        this.serviceOption = await _.uniqBy(serviceArray,'id').filter((m)=> m.id !== undefined)

      }catch (error){
        this.convertAndNotifyError(error)
      }
    },

    async getRoles() {
      try {
        const Response = await settings.getRolesWithoutPagination();
        this.roleOptions = Response.data.data.map((x) => ({
          name: x.name,
          id: x.id,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },


    async serviceFilter(array){
      if (array.length !==0){
        this.eventSettings = {dataSource: this.save_shifts.filter(item => array.includes(item.service_id))}

        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource)
      }else {
        // this.eventSettings = {dataSource: this.save_shifts}
        await this.getUserData()
        await this.getShiftData()
      }

    },

    async roleFilter(array){
      if (array){
        this.eventSettings = {
          dataSource: this.save_shifts.filter((item) =>{
            if(item.role_id){
              return item.role_id.find((roleId)=>{
                return roleId.value === array
              })
            }
          }),
        };
        await this.filterChangesAndRemoveUser(this.eventSettings.dataSource);
      }else {
        // this.eventSettings = {dataSource: this.save_shifts}
        await this.getUserData()
        await this.getShiftData()
      }

    },

    UserFilter(array){
      if (array.length !==0){
        this.employeeDataSource = this.save_employee.filter((m)=> array.includes(m.id ))
      }else {
        this.employeeDataSource = this.save_employee
      }
    }
  },

  // async beforeMount () {
  //
  //   // sessionStorage.setItem('practice_id', this.$route.query.practice_id)
  //
  //   // if (JSON.parse(sessionStorage.getItem('permission')) === null){
  //   //   const cacheUserData = (await practice.getPracticeDetails())
  //   //   sessionStorage.setItem('permission', JSON.stringify(cacheUserData.data.data.team.roles.map(x => x.name)))
  //   // }
  //
  //   // commit('SET_CURRENT_PRACTICE_ID', this.$route.query.practice_id )
  //
  // },
  async mounted () {
    // sessionStorage.setItem('practice_id', this.$route.query.practice_id)
    this.$refs.scheduler.ej2Instances.firstDayOfWeek = 1
    await this.getUserData()
    await this.getRoles()
    await this.$root.$on('indexComponentFormEventTemplate', () => {
      this.selectedCellEdit = this.selectedCell
      this.isEditShiftSidebarActive = true
    })
    await this.$root.$on('indexComponentFormEventTemplateDuplicate', () => {
      this.selectedCellEdit = this.selectedCell
      this.isDuplicateShiftSidebarActive = true
    })
    await this.$root.$on('indexComponentFormEventTemplateSingleDuplicate', () => {
      this.selectedCellView = this.selectedCell
      this.isDuplicateSingleShiftSidebarActive = true
    })

  }
})

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss'; /*print style*/

</style>
x
<style lang="scss">
[dir] .e-schedule .e-timeline-view .e-work-cells:hover:not(.e-resource-group-cells), [dir] .e-schedule .e-timeline-month-view .e-work-cells:hover:not(.e-resource-group-cells) {
  //content: "➕" !important;
  //background-color: red;
  position: relative;
  &:before{
    content: "" !important;
    color: #B2B2B2;
    font-size: 30px;
    line-height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

//[dir] .e-schedule .e-timeline-view .e-work-cells:hover:not(.e-resource-group-cells), [dir] .e-schedule .e-timeline-month-view .e-work-cells:hover:not(.e-resource-group-cells) .test_test {
//  //background: #f10000;
//  display: none;
//}


.full-height {
  height: 100% !important;
}
.e-schedule .e-timeline-view .e-resource-group-cells {
  background-color: #CCE6F4;
  height: 30px!important;
}


.e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day, .e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
  color: black;
  font-weight: 500;
}
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: black;
  font-weight: 500;

  letter-spacing: 0.4000000059604645px;
}

[dir] .e-schedule .e-schedule-toolbar .e-toolbar-items {
  background: #ffffff;

}

[dir] .e-schedule .e-schedule-toolbar .e-tbar-btn {
  background: #ffffff;
}


[dir] .btn.btn-icon {
  padding: 0.715rem 0.736rem;
  background-color: white;
}

[dir] .e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: white;
}

[dir=ltr] .vs--single .vs__dropdown-toggle {
  background-color: white;

}

.e-schedule .e-timeline-view .e-resource-tree-icon {
  float: right;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 3px;
}

.employee-name {
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.e-resource-column{
  table-layout: fixed !important;
  width: 15% !important;

}


#scheduler {
  min-height: 660px;
}

@media (#scheduler min-height: 300px) and (#scheduler max-height: 600px) {
  .e-quick-popup-wrapper {

    bottom: 0px !important;
    top: auto !important;

  }
}

e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 8px 18px 2px 26px;
}

.e-quick-popup-wrapper {
  color: rgba(0, 0, 0, 0.87);
  max-width: 75px;
  min-width: 320px;
  min-height: 360px;
  max-height: 75px;
  opacity: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.e-schedule .e-timeline-view .e-appointment, .e-schedule .e-timeline-month-view .e-appointment {
  color: #fff;
  overflow: hidden;
  position: absolute;
  height: 95px;
  border-radius: 4px;
  margin-top: 0px;


}


.e-schedule .e-timeline-view .e-appointment .e-appointment-details {
  padding: 0px !important;
}


[dir] .e-schedule .e-timeline-view .e-child-node, [dir] .e-schedule .e-timeline-month-view .e-child-node{
  white-space: pre-wrap;
}
.employee-image {
  background-color: rgba(27, 154, 170, 0.12) !important;
  margin: 5px;
  padding: 5px;
}

.e-today{
  display: none !important;      // Hide today
}


.e-agenda-item{
  margin: 5px;
  color: #ffffff;
}

// td.e-header-cells {
//   display: none;
// }

</style>
